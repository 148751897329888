<template>
  <b-row>
    <b-col cols="6" class="no-padding">
      <base-64-image place="aspe-ossau" type="meteofrance-bra"></base-64-image>
    </b-col>
    <b-col cols="6" class="no-padding">
      <base-64-image place="haute-bigorre" type="meteofrance-bra"></base-64-image>
    </b-col>
  </b-row>
</template>

<script>
import Base64Image from '@/components/Base64Image.vue';

export default {
  name: 'BRAs',
  components: {
    Base64Image,
  },
};
</script>
